<template>
  <v-container fluid class="px-0">
    <MyDialog ref="itemsDialog" @onConfirm="deleteItem" />
    <MyDataTable
      title="بانک های من"
      subtitle="مدیریت بانک"
      addtitle="ایجاد بانک جدید"
      :addpageRoute="
        `${$route.path}/create${
          $route.query.page ? '?page=' + $route.query.page : ''
        }`
      "
      :headers="headers"
      :items="items"
      :loading="loading"
      :pagesLength="pagesLength"
      @onPageChanged="GetList"
      @onDeleteClick="deleteItem"
      @onEditClick="onEditClick"
      class="mt-n13 moholand-shadow"
    >
      <!-- <template #middleButtonTag="{ row }">
        <v-btn
          class="mx-2"
          dark
          small
          color="primary"
          @click="onDetailsClick(row)"
        >
          <v-icon small dark>mdi-apps</v-icon>
          مشاهده
        </v-btn>
      </template> -->
      <template #contentTag="{ row }">
        <td>{{ row.name }}</td>
        <td>{{ row.cart_number }}</td>
      </template>
    </MyDataTable>
  </v-container>
</template>

<script>
import { Constants } from "@/constants/GlobalConstants";
import MyAxios from "@/constants/MyAxios";
import MyDialog from "@/components/MyDialog";
import MyDataTable from "@/components/MyDataTable";
import { getErrorText } from "@/constants/GlobalConstants";

export default {
  created() {
    this.GetList();
  },
  components: {
    MyDialog,
    MyDataTable,
  },
  methods: {
    GetList() {
      if (!this.loading) this.loading = true;

      MyAxios.get("/banks/index", {
        params: {
          page: this.$route.query?.page || 1,
          conditions: {
            user_id: this.$store.state?.userData?.id || 0,
          },
        },
      })
        .then((response) => {
          this.loading = false;
          this.items = response.data.data;
          this.pagesLength = response.data.last_page;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    onEditClick(item) {
      this.$router.push({
        path: "/banksVendor/edit/" + item.id,
        query: this.$route.query || {},
      });
    },
    onDetailsClick(item) {
      this.$router.push({
        name: "Sources",
        params: {
          parentId: item.id,
        },
      });
    },
    deleteItem(item) {
      MyAxios.delete(`/banks/${item.id}/delete`)
        .then((response) => {
          console.log("delete", response);
          this.$root.$emit("toast", {
            text: "آیتم با موفقیت حذف شد.",
            type: "success",
          });
          this.GetList();
        })
        .catch((error) => {
          console.log("laws", error.response);
        });
    },
    updateItems(items) {
      this.items = items;
    },
  },
  data() {
    return {
      loading: true,
      items: [],
      selectedData: {},
      page: 1,
      pagesLength: 1,
      path: "/banks/index",
      pathParams: {
        conditions: {
          user_id: this.$store.state?.userData?.id || 0,
        },
      },
      headers: [
        {
          text: "عنوان",
          align: "start",
          value: "name",
        },
        { text: "شماره کارت", value: "cart_number" },
        { text: "عملیات", value: "actions", sortable: false },
      ],
    };
  },
};
</script>

<style></style>
